import PageLink from '@/components/page/link';
import StyledImage from '@/components/styledImage';
import { axiosClient } from '@/data/axios';
import { INotification, NotificationType } from '@/dto/opensearch';
import { useDismissibleMessage } from '@/hooks/useDismissibleMessage';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useMenu } from '@/providers/menu';
import { Close as CloseIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import {
	Badge,
	Box,
	Button,
	Chip,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Skeleton,
	Stack,
	Tab,
	Tabs,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SyntheticEvent, useEffect, useState } from 'react';

const initialMessages = [ /*{
	id           : '190',
	title        : 'View your bank accounts and credit card transactions',
	content      : 'You can now link your bank accounts and credit cards to access real-time transaction updates.',
	learnMoreLink: 'https://www.invoiss.com/dashboard/management/transactions',
},*/{
		id           : '203',
		title        : 'Join us in enhancing your experience',
		content      : 'Book a session with us to improve Invoiss',
		learnMoreLink: 'https://calendly.com/invoiss/invoiss-introduction',
	} ];

function MenuContent( {
	title,
	message,
	learnMoreLink,
	onIndividualMessageDismiss,
	type,
}: { title: string, message: string, learnMoreLink?: string, onIndividualMessageDismiss: () => void, type: string } ) {
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	const isOwner = usePermissions( [ 'OWNER' ] );
	
	return (
		<ListItem>
			<ListItemAvatar>
				<StyledImage
					alt='Invoiss Logo'
					sx={{ height: 30 }}
					src={`/images/invoiss-logo-${isDarkMode ? 'light' : 'dark'}.png`}
				/>
			</ListItemAvatar>
			<ListItemText
				primary={(
					<Stack direction='row' alignItems='center' spacing={1}>
						<Typography variant='h6' fontWeight='500'>{title}</Typography>
						<Tooltip title='System Notification'>
							<Box>
								<Chip
									color={type === NotificationType.EMAIL ? 'error' : 'primary'}
									label={type}
									variant='alpha'
									sx={{ zoom: .8 }}
								/>
							</Box>
						</Tooltip>
					</Stack>
				)}
				secondary={(
					<Box>
						<Box>
							{message}
						</Box>
						{learnMoreLink && isOwner && <PageLink href={learnMoreLink}>Let's do it</PageLink>}
					</Box>
				)}
			/>
			<Tooltip title='Dismiss Notification'>
				<IconButton onClick={onIndividualMessageDismiss}>
					<CloseIcon sx={{ fontSize: 17 }}/>
				</IconButton>
			</Tooltip>
		</ListItem>
	);
}

function NoNotifications() {
	return (
		<Box sx={{ p: 2, textAlign: 'center' }}>
			<Typography variant='h6' fontWeight='500'>No notifications</Typography>
		</Box>
	);
}

function SystemNotifications( { messages, value, dismissMessage } ) {
	if ( value !== 0 ) {
		return null;
	}
	if ( messages.length === 0 ) {
		return <NoNotifications/>;
	}
	return messages.map( ( message ) => (
		<MenuContent
			key={message.id}
			title={message.title}
			message={message.content}
			learnMoreLink={message.learnMoreLink}
			onIndividualMessageDismiss={() => dismissMessage( message.id )}
			type={'System'}
		/>
	) );
}

function UserNotifications( {
	notifications,
	value,
	selectedValue,
	type,
	dismissNotification,
	isFetching,
}: { notifications: INotification[], value: number, selectedValue: number, type: NotificationType, dismissNotification: ( messageId: string ) => Promise<void>, isFetching: boolean } ) {
	if ( value !== selectedValue ) {
		return null;
	}
	if ( notifications.length === 0 ) {
		return <NoNotifications/>;
	}
	
	if ( isFetching ) {
		return <Skeleton variant='rectangular' height={100}/>;
	}
	
	return notifications.map( ( notification ) => (
		<MenuContent
			key={notification.id}
			title={notification.title}
			message={notification.content}
			onIndividualMessageDismiss={() => dismissNotification( notification.id! )}
			type={type}
		/>
	) );
}

function MenuSubContent( {
	closeMenu,
	updateMessageCount,
	notifications,
	dismissNotification,
	isFetching,
}: { closeMenu: () => void, updateMessageCount: ( count: number ) => void, notifications: INotification[], dismissNotification: ( notificationId: string ) => Promise<void>, isFetching: boolean } ) {
	const [ value, setValue ] = useState( 0 );
	const { messages, dismissMessage } = useDismissibleMessage( initialMessages, 'dismissedMessageIds' );
	const [ internalNotifications, setInternalNotifications ] = useState<INotification[]>( [] );
	const { staff } = useUserInfo();
	
	useEffect( () => {
		updateMessageCount( notifications.length + messages.length );
	}, [ messages ] );
	
	useEffect( () => {
		setInternalNotifications( notifications );
	}, [ notifications ] );
	
	const onDismiss = async ( notificationId: string ) => {
		setInternalNotifications( internalNotifications.filter( ( x ) => x.id !== notificationId ) );
		await dismissNotification( notificationId );
	};
	
	return (
		<Box sx={{ width: { xs: '100%', sm: 500 }, p: 1 }}>
			<Stack p={1} direction='row' alignItems='center' justifyContent='space-between'>
				<Typography variant='h4' fontWeight='500'>Notifications</Typography>
				<Stack direction='row' spacing={1} alignItems='center'>
					{Boolean( messages.length ) && (
						<Button
							variant='text'
							onClick={() => {
								messages.forEach( ( message ) => dismissMessage( message.id ) );
							}}>
							Dismiss All
						</Button>
					)}
					<IconButton onClick={closeMenu}>
						<CloseIcon/>
					</IconButton>
				</Stack>
			</Stack>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={( event: SyntheticEvent, newValue: number ) => setValue( newValue )}>
					<Tab label='System'/>
					<Tab label='Payments'/>
					<Tab label='Emails'/>
				</Tabs>
			</Box>
			<SystemNotifications value={value} messages={messages} dismissMessage={dismissMessage}/>
			<UserNotifications
				value={value}
				selectedValue={1}
				notifications={internalNotifications.filter( ( x ) => x.type === NotificationType.PAYMENT )}
				type={NotificationType.PAYMENT}
				dismissNotification={onDismiss}
				isFetching={isFetching}
			/>
			<UserNotifications
				value={value}
				selectedValue={2}
				notifications={internalNotifications.filter( ( x ) => x.type === NotificationType.EMAIL )}
				type={NotificationType.EMAIL}
				dismissNotification={onDismiss}
				isFetching={isFetching}
			/>
		</Box>
	);
}

export default function MenuContentWrapper() {
	const { showMenu } = useMenu();
	const { staff } = useUserInfo();
	
	const [ messageCount, setMessageCount ] = useState( initialMessages.length );
	const [ notifications, setNotifications ] = useState<INotification[]>( [] );
	// Only keeping track of dismissed notifications in the component state
	// to avoid unnecessary re-renders and due to delay on OpenSearch to refresh
	const [ dismissedNotifications, setDismissedNotifications ] = useState<string[]>( [] );
	
	const localStorageMessagesIds = JSON.parse( localStorage.getItem( 'dismissedMessageIds' ) ?? '[]' );
	
	const remainingMessages = initialMessages.filter( ( message ) =>
		!localStorageMessagesIds?.includes( message.id ),
	);
	useEffect( () => {
		setMessageCount( notifications.length + remainingMessages.length );
	}, [] );
	
	const { refetch, isFetching, isRefetching } = useQuery( {
		queryKey      : [ 'notifications' ],
		refetchOnMount: true,
		queryFn       : async () => {
			if ( staff?.company.id ) {
				const { data } = await axiosClient.get<INotification[]>( `/api/user/notifications/${staff?.company.id}` );
				return data;
			}
			return [];
		},
		onSuccess: ( data ) => {
			setMessageCount( remainingMessages.length + data.length );
			setNotifications( data.filter( ( x ) => !dismissedNotifications.includes( x.id! ) ) );
		},
	} );
	
	const dismissNotification = async ( messageId: string ) => {
		setNotifications( notifications.filter( ( x ) => x.id !== messageId ) );
		setDismissedNotifications( [ ...dismissedNotifications, messageId ] );
		await axiosClient.delete( '/api/user/notifications', { data: { notificationId: messageId } } );
	};
	
	if ( !staff ) return null;
	
	return (
		<Tooltip title='Notifications'>
			<IconButton
				onClick={( e ) => showMenu( ( { closeMenu } ) => (
					<MenuSubContent
						closeMenu={() => {
							closeMenu();
							refetch();
						}}
						updateMessageCount={setMessageCount}
						notifications={notifications}
						dismissNotification={dismissNotification}
						isFetching={isFetching || isRefetching}
					/>
				), e.currentTarget, {
					anchorOrigin   : { vertical: 'bottom', horizontal: 'center' },
					transformOrigin: { vertical: 'top', horizontal: 'center' },
				} )}>
				<Badge
					badgeContent={messageCount}
					color='error'>
					<NotificationsIcon/>
				</Badge>
			</IconButton>
		</Tooltip>
	);
}
